import PropTypes from "prop-types"
import React from "react"

var classNames = require("classnames")

class ButtonGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    let isCenteredMobile
    this.props.isCenteredMobile
      ? (isCenteredMobile = this.props.isCenteredMobile)
      : (isCenteredMobile = true)

    var wrapperClass = classNames({
      "button-group-wrapper": true,
      "is-centered-mobile": isCenteredMobile
    })

    var buttonGroupClass = classNames({
      "button-group": true,
      tight: this.props.tight,
      "is-centered": this.props.isCentered,
      "align-right": this.props.alignRight,
      "align-left": this.props.alignLeft,
      "reverse-buttons-mobile": this.props.reverse
    })
    return (
      <div className={wrapperClass}>
        <div
          className={
            buttonGroupClass +
            ` has-${React.Children.count(this.props.children)}-button`
          }>
          {this.props.children}
        </div>
      </div>
    )
  }
}

ButtonGroup.propTypes = {
  isCentered: PropTypes.bool,
  isCenteredMobile: PropTypes.bool,
  tight: PropTypes.tight,
  alignLeft: PropTypes.bool,
  alignRight: PropTypes.bool,
  children: PropTypes.node,
  reverse: PropTypes.bool
}

export default ButtonGroup
