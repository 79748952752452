import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Button from "@components/Button/Button"
import ButtonGroup from "@components/Button/ButtonGroup"
import CTA from "./CTA"
// import { Image, Video, Transformation, CloudinaryContext } from 'cloudinary-react';

var classNames = require("classnames")

class GetStarted extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let buttonColumns = (
      <div className="column">
        <Button
          contained
          href={this.props.buttonUrl}
          buttonText={this.props.buttonText}
        />
      </div>
    )

    if (this.props.buttons && this.props.buttons.length > 1) {
      buttonColumns = (
        <ButtonGroup isCentered>
          {this.props.buttons.map(button => (
            <Button
              key={button.button.href}
              href={button.button.href}
              buttonText={button.button.buttonText}
            />
          ))}
        </ButtonGroup>
      )
    } else if (this.props.buttons) {
      buttonColumns = this.props.buttons.map(button => (
        <Button
          key={button.button.href}
          href={button.button.href}
          buttonText={button.button.buttonText}
        />
      ))
    }

    return (
      <CTA
        className={this.props.className ? this.props.className : ""}
        sideColumnIs={this.props.sideColumnIs}
        headingSideColumnIs={this.props.headingSideColumnIs}
        headingColumns={
          <div
            className={`column ${
              this.props.centerHeading ? "has-text-centered" : ""
            }`}>
            {this.props.subheading && <h4>{this.props.subheading}</h4>}
            <h3>{this.props.heading}</h3>
          </div>
        }
        textColumns={
          <div
            className={`column ${
              this.props.centerText ? "has-text-centered" : ""
            }`}>
            {this.props.paragraph}
          </div>
        }
        buttonColumns={buttonColumns}
      />
    )
  }
}

GetStarted.propTypes = {
  className: PropTypes.string,
  centerHeading: PropTypes.bool,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  centerText: PropTypes.bool,
  paragraph: PropTypes.element,
  buttons: PropTypes.array,
  buttonUrl: PropTypes.string,
  buttonText: PropTypes.string,
  sideColumnIs: PropTypes.number,
  headingSideColumnIs: PropTypes.number
}

export default GetStarted
